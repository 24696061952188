import React from "react"
import styled from "styled-components"

import PageWrapper from "../../../styles/PageWrapper"
import SEO from "../../../components/seo"

import Finder from "../../../components/Finder"

const FinderPageWrapper = styled(PageWrapper)`
  padding: 0;
`

const FinderPage = () => (
  <FinderPageWrapper>
    <SEO title="Find a f'real - Kwik Trip" />
    <Finder storeFilter={`Kwik Trip`} />
  </FinderPageWrapper>
)

export default FinderPage
